import React from "react";
import Header from "./components/Header";
// import SelectPasses from "./components/pages/SelectPasses";
import HomePage from "./components/pages/HomePage";
import BuyDayPasses from "./components/pages/BuyDayPasses";
import ActivatePass from "./components/pages/ActivatePass";
//import SelectPasses from "./components/pages/SelectPasses";
import SuccessPage from "./components/pages/Success";
import { BrowserRouter, Switch, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Header></Header>
      <Switch>
        <Route path="/" exact component={HomePage}></Route>
        <Route
          path="/buy-day-passes/:orderId?"
          exact
          component={BuyDayPasses}
        ></Route>
        <Route path="/success/:orderId" exact component={SuccessPage}></Route>
        <Route path="/activate" exact component={ActivatePass}></Route>
        {/* <Route path="/select-passes" exact component={SelectPasses}></Route> */}
      </Switch>
    </BrowserRouter>
  );
}

export default App;
