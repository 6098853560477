import cloneDeep from "lodash/cloneDeep";
import initialState from "../initialState.json";

const orderSuccessReducer = (
  state = initialState.orderSuccessReducer,
  action
) => {
  let order = cloneDeep(state);
  let orderReducer = false;
  if (
    action.hasOwnProperty("payload") &&
    action.payload.hasOwnProperty("orderReducer")
  ) {
    orderReducer = action.payload.orderReducer;
  }

  switch (action.type) {
    case "ORDER_SUCCESS_SET_ORDER":
      if (orderReducer) {
        order = orderReducer;
      }
      break;
    case "ORDER_SUCCESS_UPDATE":
      Object.assign(order, action.payload);
      break;
    default:
      break;
  }

  return order;
};

export default orderSuccessReducer;
