import { combineReducers } from "redux";
import productsReducer from "./products";
import activatePassFormReducer from "./activatePassForm";
import orderReducer from "./order";
import orderSuccessReducer from "./orderSuccess";

const rootReducer = combineReducers({
  productsReducer,
  activatePassFormReducer,
  orderReducer,
  orderSuccessReducer
});

export default rootReducer;
