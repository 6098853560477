import React from "react";
import { Link } from "react-router-dom";

const HomePage = (props) => {
  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col col-md-6 offset-md-3">
            <h1>Express Pass Purchase &amp; Pickup</h1>
            <Link to="/buy-day-passes" className="button-link solid">
              Buy a Day or Multiday Pass
            </Link>
            <Link to="/activate" className="button-link">
              Activate a Pass Only
            </Link>
            <p>Day or multi pass purchases, only!</p>
            <p>For all other purchases or packages go here.</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HomePage;
