import React, { Component } from "react";
import logoDesktop from "../svg/logo-cardrona.svg";
import logoMobile from "../svg/logo-cardrona-mobile.svg";
import { Link } from "react-router-dom";

class Header extends Component {
  render() {
    return (
      <header>
        <div className="wrapper">
          <Link to="/" className="logo">
            <img
              src={logoDesktop}
              className="logo-desktop"
              alt="Cardrona Alpine Resort / New Zealand"
            ></img>
            <img
              src={logoMobile}
              className="logo-mobile"
              alt="Cardrona Alpine Resort / New Zealand"
            ></img>
          </Link>
        </div>
      </header>
    );
  }
}

export default Header;
