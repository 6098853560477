import cloneDeep from "lodash/cloneDeep";
import initialState from "../initialState.json";

const orderReducer = (state = initialState.orderReducer, action) => {
  let order = cloneDeep(state);
  let products = false;
  if (
    action.hasOwnProperty("payload") &&
    action.payload.hasOwnProperty("products")
  ) {
    products = action.payload.products;
  }

  switch (action.type) {
    case "BUY_DAY_PASS_SET_ORDER":
      order = action.payload.order;
      break;
    case "BUY_DAY_PASS_CLEAR_ORDER":
      order = initialState.orderReducer;
      break;
    case "BUY_DAY_PASS_UPDATE_FORM":
      if (!isNaN(action.payload.arrayIndex)) {
        let item = order.items[action.payload.arrayIndex];
        if (item) {
          let updateTotals = false;
          const fieldName = Object.keys(action.payload.field).shift();

          if (fieldName === "qty") {
            updateTotals = true;
            const fieldValue = action.payload.field.qty;
            if (fieldValue === "increment") {
              item.qty++;
            } else if (fieldValue === "decrement") {
              item.qty--;
              if (item.qty < 0) item.qty = 0;
            }
          } else {
            Object.assign(item, action.payload.field);
            if (fieldName === "productFareTypeCode") updateTotals = true;
          }

          if (updateTotals === true) {
            const product = products.find(
              product => product.code === "SUM-PASS-FULL"
            );
            const fareType = product.fareTypes.find(
              fareType => fareType.code === item.productFareTypeCode
            );

            item.total = item.qty * fareType.priceGross;

            order.total = 0;
            order.items.map(item => {
              order.total += item.total;
            });
          }
        }
      } else {
        Object.assign(order, action.payload);
      }
      break;
    case "BUY_DAY_PASS_ADD_ORDER_ITEM":
      order.items[order.items.length] = initialState.orderReducer.items[0];
      break;
    case "BUY_DAY_PASS_REMOVE_ORDER_ITEM":
      if (!isNaN(action.payload.arrayIndex)) {
        order.items.splice(action.payload.arrayIndex, 1);
      }
      break;
    default:
      break;
  }

  return order;
};

export default orderReducer;
