import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { buyDayPassUpdateForm, buyDayPassRemoveOrderItem } from "../../actions";
// import uuidv4 from "uuid/v4";

import iconMinus from "../../svg/icon-minus.svg";
import iconPlus from "../../svg/icon-plus.svg";
import iconInfo from "../../svg/icon-info.svg";
import iconMtbGuest from "../../svg/icon-mtb-guest.svg";

const OrderItem = props => {
  const { orderItemIndex } = props;
  const products = useSelector(state => state.productsReducer);
  const order = useSelector(state => state.orderReducer);
  const orderItem = order.items[orderItemIndex];
  const dispatch = useDispatch();
  // const boxHeader =
  //   buyDayPasses.passes.length === 1
  //     ? "Buy Day Passes"
  //     : "Guest " + (orderItemIndex + 1);
  const boxHeader = "Day Pass Guest " + (orderItemIndex + 1);

  // const productsReducer = useSelector(state => state.productsReducer);
  // const product = productsReducer.find(
  //   product => product.code === "SUM-PASS-FULL"
  // );
  let fareType = false;
  let product = products.find(product => product.code === "SUM-PASS-FULL");
  if (product) {
    fareType = product.fareTypes.find(
      fareType => fareType.code === orderItem.productFareTypeCode
    );
  } else {
    product = {
      fareTypes: []
    };
  }

  let removeGuestButton = "";
  if (order.items.length > 1) {
    removeGuestButton = (
      <span
        className="remove-guest-button"
        onClick={() =>
          dispatch(
            buyDayPassRemoveOrderItem({
              arrayIndex: orderItemIndex,
              products
            })
          )
        }
      >
        [x]
      </span>
    );
  }
  return (
    <React.Fragment>
      <div className="box-header">
        <img src={iconMtbGuest} alt="MTB Day Pass"></img> {boxHeader}{" "}
        {removeGuestButton}
      </div>
      <div className="box-content">
        <div className="row">
          <div className="col-12">
            <select
              onChange={event =>
                dispatch(
                  buyDayPassUpdateForm({
                    arrayIndex: orderItemIndex,
                    field: { productFareTypeCode: event.target.value },
                    products
                  })
                )
              }
              value={orderItem.productFareTypeCode}
            >
              {product.fareTypes.map(fareType => (
                <option value={fareType.code} key={fareType.code}>
                  {fareType.description}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row no-gutters product-container">
          <div className="col product">
            <div className="num-days"># of Days</div>
            <div className="desc">Buy multi days &amp; save</div>
            <div className="savings"></div>
          </div>
          <div className="col qty">
            <div className="row no-gutters align-items-center">
              <div className="col">
                <img
                  src={iconMinus}
                  onClick={() =>
                    dispatch(
                      buyDayPassUpdateForm({
                        arrayIndex: orderItemIndex,
                        field: { qty: "decrement" },
                        products
                      })
                    )
                  }
                  alt="Decrement Days"
                ></img>
              </div>
              <div className="col">
                <span>{orderItem.qty}</span>
              </div>
              <div className="col">
                <img
                  src={iconPlus}
                  onClick={() =>
                    dispatch(
                      buyDayPassUpdateForm({
                        arrayIndex: orderItemIndex,
                        field: { qty: "increment" },
                        products
                      })
                    )
                  }
                  alt="Increment Days"
                ></img>
              </div>
            </div>
          </div>
        </div>
        <div className="row total sub-total">
          <div className="col-12">
            <div className="total-price">${orderItem.total}</div>
            <div className="savings">
              {/*You save $0.00 | */}${fareType.priceGross}/day
            </div>
          </div>
        </div>

        <h4>Guest Details for Pass</h4>
        <div className="row">
          <div className="col-12">
            <input
              type="text"
              value={orderItem.firstName}
              placeholder="First Name"
              required
              onChange={event =>
                dispatch(
                  buyDayPassUpdateForm({
                    arrayIndex: orderItemIndex,
                    field: { firstName: event.target.value }
                  })
                )
              }
            ></input>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <input
              type="text"
              name="lastName"
              value={orderItem.lastName}
              placeholder="Last Name"
              required
              onChange={event =>
                dispatch(
                  buyDayPassUpdateForm({
                    arrayIndex: orderItemIndex,
                    field: { lastName: event.target.value }
                  })
                )
              }
            ></input>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <input
              type="email"
              name="email"
              value={orderItem.email}
              placeholder="Email Address"
              required
              email="email"
              onChange={event =>
                dispatch(
                  buyDayPassUpdateForm({
                    arrayIndex: orderItemIndex,
                    field: { email: event.target.value }
                  })
                )
              }
            ></input>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-12">
            <h4 className="link-pass">
              Fast Link a Physical Pass{" "}
              <img src={iconInfo} alt="Activate Pass Info"></img>
            </h4>
            <input
              type="text"
              name="passNumber"
              value={orderItem.passNumber}
              placeholder="ABC123"
              min="8"
              max="8"
              onChange={event =>
                dispatch(
                  buyDayPassUpdateForm({
                    arrayIndex: orderItemIndex,
                    field: { passNumber: event.target.value }
                  })
                )
              }
            ></input>
          </div>
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default OrderItem;
