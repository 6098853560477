import React from "react";
import BuyDayPass from "../partials/BuyDayPass";

const BuyDayPasses = props => {
  console.log(props);
  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col col-md-6 offset-md-3">
            <h1>Purchase &amp; Load MTB Day Passes</h1>
            <p>
              Day or multiday pass purchases, only! For all other purchases go
              here.
            </p>
            <ul>
              <li className="icon-alarm">
                Go straight to the lift by loading a MyPass card online
              </li>
              <li className="icon-tick">Guaranteed lowest price</li>
              <li className="icon-calendar">
                Passes are valid for the entire 2020 season at Cardrona
              </li>
            </ul>
          </div>
        </div>
        <BuyDayPass
          history={props.history}
          location={props.location}
          match={props.match}
        ></BuyDayPass>
      </div>
    </React.Fragment>
  );
};

export default BuyDayPasses;
