const prod = {
  apiDomain: "https://test.codestruct.dev"
};
const dev = {
  apiDomain: "http://localhost:5000"
};

const Settings = process.env.NODE_ENV === "production" ? prod : dev;

export default Settings;
