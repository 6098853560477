import React from "react";
import { useSelector } from "react-redux";
import ActivatePassForm from "../partials/ActivatePassForm";

const SelectPasses = props => {
  const activatePassForm = useSelector(state => state.activatePassFormReducer);

  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col col-md-6 offset-md-3">
            <h1>Activate Your MyPass Here</h1>
            <ul>
              <li className="icon-alarm">
                Already purchased a lift ticket online? Then skip the queues by
                collecting a pass from an outlet and pre-activating it below.
              </li>
              <li className="icon-tick">
                You must use the same email address that was provided when your
                lift ticket was purchased.
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col col-md-6 offset-md-3 product-selection-box-wrapper">
            <ActivatePassForm></ActivatePassForm>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SelectPasses;
