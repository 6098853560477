import React, { Component, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { updateForm } from "../../actions";
import iconInfo from "../../svg/icon-info.svg";

const ProductSelectionBox = props => {
  const activatePassForm = useSelector(state => state.activatePassFormReducer);
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <div className="product-selection-box pass-activator">
        <div className="box-header">Pass Activator</div>
        <div className="box-content">
          <form>
            <div className="form">
              <h4>Guest Details for Pass</h4>
              <div className="row">
                <div className="col-12">
                  <input
                    type="text"
                    name="firstName"
                    required
                    value={activatePassForm.firstName}
                    placeholder="First Name"
                    onChange={event =>
                      dispatch(updateForm({ firstName: event.target.value }))
                    }
                  ></input>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <input
                    type="text"
                    name="lastName"
                    required
                    value={activatePassForm.lastName}
                    placeholder="Last Name"
                    onChange={event =>
                      dispatch(updateForm({ lastName: event.target.value }))
                    }
                  ></input>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <input
                    type="email"
                    name="email"
                    required
                    value={activatePassForm.email}
                    placeholder="Email Address"
                    onChange={event =>
                      dispatch(updateForm({ email: event.target.value }))
                    }
                  ></input>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h4 className="link-pass">
                    Fast Link a Physical Pass{" "}
                    <img src={iconInfo} alt="Activate Pass Info"></img>
                  </h4>
                  <input
                    type="text"
                    name="passNumber"
                    value={activatePassForm.passNumber}
                    placeholder="ABC123"
                    min="8"
                    max="8"
                    required
                    onChange={event =>
                      dispatch(
                        updateForm({
                          passNumber: event.target.value
                        })
                      )
                    }
                  ></input>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label className="terms">
                    <input
                      type="checkbox"
                      name="termsAgreed"
                      value="1"
                      checked={activatePassForm.termsAgreed}
                      required
                      onChange={event =>
                        dispatch(
                          updateForm({ termsAgreed: event.target.checked })
                        )
                      }
                    ></input>{" "}
                    I agree to the <Link to="/">Terms &amp; Conditions</Link>
                  </label>
                </div>
              </div>
              <div className="row button">
                <div className="col-12">
                  <button>Activate This Pass</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductSelectionBox;
