import React from "react";
import ReactDOM from "react-dom";
import "./scss/index.scss";
import App from "./App";
import WebFont from "webfontloader";
import { createStore } from "redux";
import rootReducer from "./reducers";
import { Provider } from "react-redux";
import initialState from "./initialState.json";

const store = createStore(
  rootReducer,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
WebFont.load({
  google: {
    families: ["Roboto", "Open Sans", "sans-serif"]
  }
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
