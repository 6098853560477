import cloneDeep from "lodash/cloneDeep";
import initialState from "../initialState.json";

const activatePassFormReducer = (
  state = initialState.activatePassFormReducer,
  action
) => {
  let newState = cloneDeep(state);

  switch (action.type) {
    case "UPDATE_FORM":
      newState = Object.assign({}, newState, action.payload);
      break;
    default:
      break;
  }

  return newState;
};

export default activatePassFormReducer;
