import React, { Component } from "react";
import { connect } from "react-redux";
import {
  buyDayPassUpdateForm,
  buyDayPassAddOrderItem,
  updateProductsReducer,
  buyDayPassClearOrder,
  buyDayPassSetOrder,
  orderSuccessSetOrder
} from "../../actions";
import { Link } from "react-router-dom";
// import uuidv4 from "uuid/v4";
import OrderItem from "./OrderItem";
import Settings from "../../Settings";
import ajaxLoaderLarge from "../../images/ajax-loader-large.gif";
import ajaxLoaderFormButton from "../../images/ajax-loader-form-button.gif";
//import queryString from "query-string";

class BuyDayPass extends Component {
  componentWillMount = () => {
    const dispatch = this.props.dispatch,
      products = this.props.products,
      { orderId } = this.props.match.params;

    if (orderId) {
      if (orderId !== "") {
        fetch(Settings.apiDomain + "/api/v1/order/" + orderId)
          .then(response => response.json())
          .then(json => {
            if (typeof json.status !== "undefined") {
              if (json.status === 1) {
                if (json.order) {
                  if (
                    (json.order.items.length > 0 && json.order.total === 0) ||
                    (json.order.paymentCheck &&
                      json.order.paymentCheck.paymentStatus === "SUCCESS")
                  ) {
                    this.props.history.push("/success/" + json.order.orderId);
                  } else {
                    dispatch(
                      buyDayPassSetOrder({
                        order: json.order
                      })
                    );
                  }
                }
              } else {
                if (json.status === 0) {
                  if (json.errors && json.errors.length > 0) {
                    alert(json.errors.join("\n"));
                  } else {
                    alert(
                      "There was a problem. Please try again.\n" +
                        "Error: " +
                        json.message
                    );
                  }
                }
              }
            } else {
              // alert("Failed to load order");
            }

            return true;
          })
          .catch(error => {
            alert(error);
          });
      }
    }

    if (products && products.length > 0) return true;

    fetch(Settings.apiDomain + "/api/v1/products?productCode=SUM-PASS-FULL")
      .then(response => response.json())
      .then(json => {
        if (typeof json.status !== "undefined") {
          if (json.status === 1) {
            if (json.products) {
              this.products = json.products;
              dispatch(
                updateProductsReducer({
                  products: json.products
                })
              );
            }
          } else {
            if (json.status === 0) {
              if (json.errors && json.errors.length > 0) {
                alert(json.errors.join("\n"));
              } else {
                alert(
                  "There was a problem. Please try again.\n" +
                    "Error: " +
                    json.message
                );
              }
            }
          }
        } else {
          alert("Failed to load products");
        }

        return true;
      })
      .catch(error => {
        alert(error);
      });
  };

  componentWillUnmount = () => {
    //maybe clear the order
  };

  handleFormSubmit = e => {
    e.preventDefault();
    const order = this.props.order;
    const dispatch = this.props.dispatch;

    if (order.formProcessing) return false;

    dispatch(
      buyDayPassUpdateForm({
        formProcessing: true
      })
    );
    console.log(Settings.apiDomain + "/api/v1/order/pre-process");
    fetch(Settings.apiDomain + "/api/v1/order/pre-process", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(order)
    })
      .then(response => response.json())
      .then(json => {
        if (typeof json.status !== "undefined") {
          if (json.status === 1) {
            if (json.redirectUrl) {
              window.location.href = json.redirectUrl;
            } else if (json.orderTotal === 0) {
              order.orderId = json.orderId;
              order.customLincPartyId = json.customLincPartyId;

              dispatch(buyDayPassClearOrder());

              this.props.history.push("/success/" + order.orderId);
            } else {
              dispatch(
                buyDayPassUpdateForm({
                  orderId: json.orderId,
                  customLincPartyId: json.customLincPartyId,
                  formProcessing: false
                })
              );
              alert(
                "There was a problem. Please try again.\n Error code (oID): " +
                  json.orderId
              );
            }
          } else {
            dispatch(
              buyDayPassUpdateForm({
                formProcessing: false
              })
            );

            if (json.status === 0) {
              if (json.errors && json.errors.length > 0) {
                alert(json.errors.join("\n"));
              } else {
                alert(
                  "There was a problem. Please try again.\n" +
                    "Error: " +
                    json.message
                );
              }
            }
          }
        } else {
          alert(
            "There was a problem. Please try again.\n An invalid response was received"
          );
        }

        return true;
      })
      .catch(error => {
        alert(error);
        dispatch(
          buyDayPassUpdateForm({
            formProcessing: false
          })
        );
      });
  };

  render() {
    const products = this.props.products;
    const order = this.props.order;
    const dispatch = this.props.dispatch;
    let formButtonText = "Make Payment";
    if (order.formProcessing) {
      formButtonText = <img src={ajaxLoaderFormButton}></img>;
    }
    return (
      <React.Fragment>
        <div className={"row " + (!order.loading && "d-none")}>
          <div className="col col-md-6 offset-md-3 loading-wrapper">
            <img src={ajaxLoaderLarge}></img>
          </div>
        </div>
        <div className={"row " + (order.loading && "d-none")}>
          <div className="col col-md-6 offset-md-3 buy-day-passes-box-wrapper">
            <form
              onSubmit={e => {
                this.handleFormSubmit(e);
              }}
            >
              <div className="buy-day-passes-box">
                {order.items.map((item, index) => (
                  <OrderItem
                    orderItemIndex={index}
                    key={index}
                    products={products}
                  ></OrderItem>
                ))}

                <div className="row add-guest">
                  <div className="col-12">
                    <div
                      className="add-guest-button"
                      onClick={() => dispatch(buyDayPassAddOrderItem())}
                    >
                      <div className="add-guest-button-text">
                        Add another Guest
                      </div>
                    </div>
                  </div>
                </div>

                <div className="box-content">
                  <div className="row">
                    <div className="col-12">
                      <label className="checkbox-subscribe">
                        <input
                          type="checkbox"
                          name="subscribe"
                          value="1"
                          checked={order.subscribe}
                          onChange={event =>
                            dispatch(
                              buyDayPassUpdateForm({
                                subscribe: event.target.checked
                              })
                            )
                          }
                        ></input>{" "}
                        Email me about snow, deals &amp; other cool stuff
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <label className="checkbox-terms">
                        <input
                          type="checkbox"
                          name="termsAgreed"
                          value="1"
                          required
                          checked={order.termsAgreed}
                          onChange={event =>
                            dispatch(
                              buyDayPassUpdateForm({
                                termsAgreed: event.target.checked
                              })
                            )
                          }
                        ></input>{" "}
                        I agree to the{" "}
                        <Link to="/">Terms &amp; Conditions</Link>
                      </label>
                    </div>
                  </div>

                  <div className="row total grand-total">
                    <div className="col-12">
                      <div className="total-text">Total</div>
                      <div className="total-price">${order.total}</div>
                      {/* <div className="savings">You saved ${order.saving}</div> */}
                    </div>
                  </div>
                  <div className="row button">
                    <div className="col-12">
                      <p>Pay using a debit or credit card</p>
                      <button disabled={order.formProcessing}>
                        {/* Buy Pass{order.items.length > 1 ? "es" : ""} */}
                        {formButtonText}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    products: state.productsReducer,
    order: state.orderReducer
  };
};
export default connect(mapStateToProps)(BuyDayPass);
