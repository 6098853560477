import React, { Component } from "react";
import { connect } from "react-redux";
import { orderSuccessSetOrder } from "../../actions";
import Settings from "../../Settings";
import SuccessPassInfo from "../partials/SuccessPassInfo";
//import queryString from "query-string";

class Success extends Component {
  componentWillMount = () => {
    const dispatch = this.props.dispatch,
      { orderId } = this.props.match.params;

    if (orderId) {
      if (orderId !== "") {
        fetch(Settings.apiDomain + "/api/v1/order/" + orderId)
          .then(response => response.json())
          .then(json => {
            if (typeof json.status !== "undefined") {
              if (json.status === 1) {
                if (json.order) {
                  if (
                    (json.order.items.length > 0 && json.order.total === 0) ||
                    (json.order.paymentCheck &&
                      json.order.paymentCheck.paymentStatus === "SUCCESS")
                  ) {
                    dispatch(
                      orderSuccessSetOrder({
                        orderReducer: json.order
                      })
                    );
                  } else {
                    this.props.history.push(
                      "/buy-day-passes/" + json.order.orderId
                    );
                  }
                }
              } else if (json.status === 2) {
                // Order not found
                this.props.history.push("/buy-day-passes");
              } else if (json.status === 0) {
                if (json.errors && json.errors.length > 0) {
                  alert(json.errors.join("\n"));
                } else {
                  alert(
                    "There was a problem. Please try again.\n" +
                      "Error: " +
                      json.message
                  );
                }
              }
            } else {
              this.props.history.push("/buy-day-passes");
            }

            return true;
          })
          .catch(error => {
            alert(error);
          });
      }
    }
  };

  render() {
    const order = this.props.orderSuccess;
    const today = new Date();
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    const monthName = months[today.getMonth()];
    const dateString =
      today.getDate() + " " + monthName + " " + today.getFullYear();
    return (
      <React.Fragment>
        <div className="container">
          <div className="row">
            <div className="col col-md-6 offset-md-3">
              <h1>Success. Purchase Complete</h1>
            </div>
          </div>
          <div className="row">
            <div className="col col-md-6 offset-md-3">
              <div className="order-success-box">
                {order.items
                  .filter(item => item.qty > 0)
                  .map((item, index) => (
                    <SuccessPassInfo item={item} key={index}></SuccessPassInfo>
                  ))}
                <div className="box-header">Payments Summary</div>
                <div className="box-content">
                  <p>
                    Reservation Number: {order.customLincPartyId}
                    <br></br>
                    Date of Reservation: {dateString}
                    <br></br>
                    Grand Total: ${order.total}.00
                    <br></br>
                    Paid in Full - Thank you
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    orderSuccess: state.orderSuccessReducer
  };
};
export default connect(mapStateToProps)(Success);
