import cloneDeep from "lodash/cloneDeep";
import initialState from "../initialState.json";

const productsReducer = (state = initialState.productsReducer, action) => {
  let newState = cloneDeep(state);

  if (action.type === "UPDATE_PRODUCTS") {
    newState = action.payload.products;
  } else if (
    (action.type === "INCREMENT" || action.type === "DECREMENT") &&
    action.payload
  ) {
    const passTypeId = action.payload.passTypeId;
    const passId = action.payload.passId;
    const passType = newState.find(passType => passType.id === passTypeId);
    const pass = passType.passes.find(pass => pass.id === passId); // pass might be false if passId === 'days'
    if (passType) {
      switch (action.type) {
        case "INCREMENT":
          if (passId === "days") {
            passType.days++;
          } else {
            pass.qty++;
          }
          break;
        case "DECREMENT":
          if (passId === "days") {
            passType.days--;
            if (passType.days < 1) passType.days = 1;
          } else {
            pass.qty--;
            if (pass.qty < 0) pass.qty = 0;
          }
          break;
        default:
          break;
      }
      passType.total = 0;
      passType.passes.map(pass => {
        let price = pass.qty * pass.price;
        if (passType.id === "day") {
          price *= passType.days;
        }
        passType.total += price;
      });
    }
  }

  return newState;
};

export default productsReducer;
