export const increment = ids => {
  return {
    type: "INCREMENT",
    payload: { passTypeId: ids.passTypeId, passId: ids.passId }
  };
};

export const decrement = ids => {
  return {
    type: "DECREMENT",
    payload: { passTypeId: ids.passTypeId, passId: ids.passId }
  };
};

export const updateForm = field => {
  return {
    type: "UPDATE_FORM",
    payload: field
  };
};

export const buyDayPassUpdateForm = payload => {
  return {
    type: "BUY_DAY_PASS_UPDATE_FORM",
    payload: payload
  };
};

export const buyDayPassAddOrderItem = () => {
  return {
    type: "BUY_DAY_PASS_ADD_ORDER_ITEM"
  };
};

export const buyDayPassRemoveOrderItem = payload => {
  return {
    type: "BUY_DAY_PASS_REMOVE_ORDER_ITEM",
    payload: payload
  };
};

export const updateProductsReducer = payload => {
  return {
    type: "UPDATE_PRODUCTS",
    payload: payload
  };
};

export const buyDayPassClearOrder = () => {
  return {
    type: "BUY_DAY_PASS_CLEAR_ORDER"
  };
};

export const buyDayPassSetOrder = payload => {
  return {
    type: "BUY_DAY_PASS_SET_ORDER",
    payload: payload
  };
};

export const orderSuccessUpdate = payload => {
  return {
    type: "ORDER_SUCCESS_UPDATE",
    payload: payload
  };
};

export const orderSuccessSetOrder = payload => {
  return {
    type: "ORDER_SUCCESS_SET_ORDER",
    payload: payload
  };
};
