import React from "react";
import ucwords from "locutus/php/strings/ucwords";

const SuccessPassInfo = props => {
  const item = props.item;

  return (
    <React.Fragment>
      <div className="box-header">
        {ucwords(item.productFareTypeCode)} {item.qty}-Day Multi Pass
      </div>
      <div className="box-content">
        <p>
          {/* <b>Valid from 1 June – 18 October 2020</b>
          <br></br> */}
          Reservation Name: {item.firstName} {item.lastName}
          <br></br>
          Contact Details: {item.email}
          <br></br>
          Total: ${item.total}.00
        </p>
      </div>
    </React.Fragment>
  );
};

export default SuccessPassInfo;
